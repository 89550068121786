header {
  padding: 1rem 0rem 0rem;
  background: linear-gradient(180deg, #0ea0ff, 5rem, #c9fafe);
}

footer {
  background: linear-gradient(135deg, #c9fafe, 50rem, #0ea0ff);
  margin-top: 6rem;
  padding: 1rem;
  text-align: left;
}


.bodyDiv {
  max-width: inherit;
  background-color: #e3faff;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  width: 100vw;
  margin: 0rem;
}

nav {
  background-color: #c9fafe;
  margin: 0;
  padding-left: 10rem;
  padding-right: 10rem;
}

.content {
  padding: 0rem 10rem 0rem;
  text-align: center;
  padding-bottom: 3rem;
  max-width: 100%;
}

table {
  border-radius: 1rem;
  margin-left: auto;
  margin-right: auto;
  empty-cells: hide;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
}

th, td {
  padding: 0.7rem;
}

th {
  background-color: #02bad6;
  color: #d8faff;
}

thead :first-child {
  border-top-left-radius: 1rem;
}

thead :last-child {
  border-top-right-radius: 1rem;
}

tbody :last-child :first-child {
  border-bottom-left-radius: 1rem;
}

tbody :last-child :last-child {
  border-bottom-right-radius: 1rem;
}

tbody tr:nth-of-type(even) {
  background-color: white;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

h4 {
  padding-bottom: 1rem;
}

.tile {
  border: none;
  background-color: #02bad6;
  color: #d8faff;
  font-weight: bold;
  height: 5rem;
  width: 16rem;
  border-radius: 1rem;
  margin: 1rem;
  font-size: 2rem;
}

a.navi {
  text-decoration: none;
}

.icon {
  height: 5rem;
  width: 5rem;
  margin-bottom: .5rem;
  margin-right: .75rem;
}

.spacer {
  height: 3rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(255, 255, 255, 0));
  margin-bottom: 2rem;
}

input, select {
  width: 7.5rem;
  text-align: center;
}

.submit {
  font-size: 1.5rem;
  border: none;
  background-color: #02bad6;
  color: #d8faff;
  font-weight: bold;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 1rem;
  margin: 1rem;
  width: auto;
}
div.dropdown {
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
}

div.clubs {
  overflow-y: scroll;
  max-height: 10rem;
  text-align: center;
  border-style: solid;
  border-color: black;
  border: 0.3rem;
  background-color: white;
  width: 20rem;
  margin: auto;
}

b.club {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  margin-left: auto;
  margin-right: auto;
}

b.club:hover {
  background-color: lightblue;
}

#clubSearch {
  width: 20rem;
  border-radius: 0.5rem;
}

canvas {
  width:100%;
  max-width:50rem;
  display: inline;
}

.statusSuccess {
  font-size: 1rem;
  background-color: #18db4c;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 1rem;
  margin: auto;
  width: fit-content;
}

.statusLoading {
  font-size: 1rem;
  background-color: orange;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 1rem;
  margin: auto;
  width: fit-content;
}

.statusFailed {
  font-size: 1rem;
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 1rem;
  margin: auto;
  width: fit-content;
}


.refreshButton {
  border-radius: 1rem;
  border: none;
  background-color: inherit;
  color: inherit;
}

.refreshButton:hover {
  background-color: darkgreen;
  font-weight: bold;
}

.betterButton {
  border-radius: 1rem;
  margin: auto;
  text-align: center;
  vertical-align: middle;;
}

.statusMarker {
  position: absolute;
  right: 5rem;
}

.rowForm {
  background-color: #c9fafe;
  padding: 1rem;
  margin: auto;
  margin-top: 2rem;
  border-radius: 1rem;
  width: 30rem;
}


body {
  background-color: #e3faff;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  margin: 0rem;
  max-width: 100%;
}

.loading {
  height: 5rem;
  aspect-ratio: initial;
  color: #0ea0ff;
  filter: brightness(0.5);
}

canvas {
  margin: auto;
  width: 50rem;
  background-color: #c9fafe;
}


.plotBundle {
  border-radius: 3rem;
  margin: auto;
  background-color: #c9fafe;
  width: fit-content;
  padding: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}